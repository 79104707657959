/* Dependency Imports */
import { useState, useContext, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useTransition, animated } from 'react-spring';
import { useNavigate, useParams } from 'react-router-dom';
import { minutesInHour, secondsInHour, secondsInMinute, addHours } from 'date-fns';

/* Project Imports */
// Contexts
import { AppointmentContext } from '../../context/AppointmentContext';
// Pages
import SelectDateTime from '../scheduling/Pages/SelectDateTime';
import FirstPage from '../scheduling/Pages/FirstPage';
import SelectLocation from '../scheduling/Pages/SelectLocation';
import PurchaserForm from '../scheduling/Pages/PurchaserForm';
import ConfirmSubmission from '../scheduling/Pages/ConfirmSubmission';
// Misc
import { IGetAppointments, IAppointmentTimeSlot } from '../../types/appointment';
import { getDoubleDigitNumber, timeZoneDate, timeZoneHour, urlName } from '../../utils/Functions';
import LoadingWrapper from '../common/LoadingWrapper';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import './appointment.css';
// import { IProjectAccess } from '../../types/user';

// Pages start at zero since they are index based
const firstPage = 0;
const buttonOffset = 0; // Offset the bottom buttons by this many pixels
const today = new Date();
today.setHours(0, 0, 0, 0);

const BookAppointment = (props: any) => {
  /* Contexts */
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { scheduleid } = useParams();
  const { project } = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [appointment, dispatchAppointment] = useContext(AppointmentContext);

  /* States */
  const [page, setPage] = useState<number>(firstPage);
  const [lastPage, setLastPage] = useState<number>(4);
  const [prevPage, setPrevPage] = useState<number>(4);
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [appointmentTimeSlots, setAppointmentTimeSlots] = useState<IAppointmentTimeSlot[][][][]>([]);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [buttonDisplay, setButtonDisplay] = useState<boolean>(true);

  /* Animations */
  const transitions = useTransition(page, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (schedules.length && (!/[0-9]/.test(schedules[0].locations[0]) || schedules[0].hideLocation)) {
      setLastPage(3);
    }
  }, [schedules]);

  /* Queries */

  useQuery<IGetAppointments>(GETAPPOINTMENTS, {
    skip: (project && Object.keys(project).length === 0) || (project && !project._id),
    fetchPolicy: 'network-only',
    variables: {
      project: project._id,
      today: today.toUTCString(),
      type: 'deal',
      cancelled: false,
      scheduleId: scheduleid ? scheduleid : '',
    },
    onCompleted: (data) => {
      setSchedules(data.getSchedules);
      if (!data.getSchedules.length) {
        return;
      }
      let newAppointmentTimeSlots: IAppointmentTimeSlot[][][][] = [];
      let noTimeslots = true;
      data.getSchedules[0].schedules?.forEach((setting) => {
        // Iterate over each day of the week until the end date
        let year = timeZoneDate(setting.date).getFullYear();
        let month = timeZoneDate(setting.date).getMonth();
        let date = timeZoneDate(setting.date).getDate();
        let timeInSeconds = setting.timeStart;

        // Check if current date is removed

        // Iterate over appointments that fit in the timeslot
        while (timeInSeconds + setting.length * secondsInMinute <= setting.timeEnd) {
          const startHour = Math.floor(timeInSeconds / secondsInHour);
          const startMin = Math.floor((timeInSeconds % secondsInHour) / secondsInMinute);
          timeInSeconds += setting.timeBetweenAppointments * secondsInMinute;

          // Check number of already booked appointments
          let numBooked = 0;
          data.appointmentMany?.forEach(
            (appointment) =>
              (numBooked += Number(
                timeZoneDate(appointment.date! ?? '').getTime() === new Date(year, month, date, startHour, startMin).getTime()
              ))
          );

          // Get timeslot info
          const endHour = startHour + Math.floor((startMin + setting.length) / minutesInHour);
          const endMin = (startMin + setting.length) % minutesInHour;
          const timeslot = getDisplayTime(startHour, startMin) + ' - ' + getDisplayTime(endHour, endMin);
          const timeslotInfo = {
            startHour: startHour,
            startMin: startMin,
            numAppointments: setting.numberOfAppointments,
            numAvailable: setting.numberOfAppointments - numBooked,
            timeslot: timeslot,
            length: setting.length,
            access: data.getSchedules[0].access,
          };

          // Initialize arrays if they are undefined
          if (!newAppointmentTimeSlots[year]) {
            newAppointmentTimeSlots[year] = [];
            noTimeslots = false;
          }
          if (!newAppointmentTimeSlots[year][month]) {
            newAppointmentTimeSlots[year][month] = [];
          }
          if (!newAppointmentTimeSlots[year][month][date]) {
            newAppointmentTimeSlots[year][month][date] = [timeslotInfo];
            continue;
          }

          // Add in new timeslot while sorting
          let index = 0;
          for (const timeslot of newAppointmentTimeSlots[year][month][date]) {
            if (startHour <= timeslot.startHour && startMin <= timeslot.startMin && setting.length < timeslot.length) break;
            ++index;
          }
          newAppointmentTimeSlots[year][month][date].splice(index, 0, timeslotInfo);
        }
      });

      if (noTimeslots) {
        setError('There are currently no available appointments for ' + project.name);
        return;
      }
      setAppointmentTimeSlots(newAppointmentTimeSlots);
      setNextEnabled(true);
    },
    onError: (err) => {
      console.log(err);
      setError('Error finding appointments for ' + project.name);
    },
  });

  /* Mutations */
  const [appointmentCreate, { loading }] = useMutation(CREATEAPPOINTMENT, {
    onCompleted: (data) => {
      setButtonDisplay(false);
      changePage(1);
    },
    onError: (error) => {
      if (error.toString() === 'Error: Appointment has already been created') {
        setError(error.toString());
      } else {
        setError('Error Creating Appointment');
      }
    },
  });

  /* Functions */
  const changePage = (offset: number) => {
    let newPage = page + offset;
    let oldPage = page;
    // Validate new page
    if (page < firstPage || lastPage < page || newPage < firstPage || lastPage < newPage) {
      newPage = firstPage;
      oldPage = lastPage;
    }
    if (newPage === firstPage) {
      setNextEnabled(true);
    }
    setPage(newPage);
    setPrevPage(oldPage);
  };

  const getDisplayTime = (hour: number, min: number) => {
    let period = Math.floor(hour / 12) % 2 ? 'p.m.' : 'a.m.';
    let displayHour = ((hour - 1) % 12) + 1;
    return getDoubleDigitNumber(displayHour) + ':' + getDoubleDigitNumber(min) + period;
  };

  const goHome = () => {
    if (project._id === '') {
      user ? navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}`) : navigate(`/${urlName(project.name)}`);
    } else {
      user ? navigate(`/dashboard/${project.name.replace(/\s+/g, '-').toLowerCase()}`) : navigate(`/${urlName(project.name)}`);
    }
  };

  const setError = (error: string) => {
    storeDispatch(showErrorSnackbar(error));
  };

  const setNext = (next: boolean) => {
    setNextEnabled(next);
  };

  const submitAppointment = async () => {
    let filteredQuestions = [];
    for (let i = 0; i < appointment.questions.length; ++i) {
      if (appointment.questions[i]) filteredQuestions.push(appointment.questions[i]);
    }
    let newAppointment = { ...appointment };
    let purchaserInfo = { ...appointment.purchaserInfo };
    if (
      !newAppointment.purchaserInfo.firstName &&
      !newAppointment.purchaserInfo.lastName &&
      !newAppointment.purchaserInfo.primaryPhone &&
      !newAppointment.purchaserInfo.email
    )
      newAppointment.questions = filteredQuestions;
    newAppointment.project = project._id;
    newAppointment.schedule = scheduleid;
    newAppointment.user = user ? user._id : null;
    newAppointment.type = schedules[0].type;
    newAppointment.location = appointment.location ? appointment.location : schedules[0].locations[0];
    newAppointment.purchaserInfo = newAppointment.purchaserInfo.numberOfGuests
      ? { ...newAppointment.purchaserInfo, numberOfGuests: parseInt(purchaserInfo.numberOfGuests, 10) }
      : {
          ...purchaserInfo,
          numberOfGuests: 0,
        };
    let appointmentRealtorType = newAppointment.purchaserInfo.realtorType;
    delete newAppointment.purchaserInfo.realtorType;
    // newAppointment.date = new Date(`${new Date(appointment.date)}-05:00`);
    newAppointment.date = new Date(addHours(new Date(appointment.date), timeZoneHour(appointment.date)));
    appointmentCreate({ variables: { record: newAppointment, realtorType: appointmentRealtorType } });
  };

  const handleButtonView = () => {
    if (page < lastPage - 1) {
      return 'Continue';
    } else if (page !== lastPage) {
      return 'Submit';
    }
  };

  // Page components go in this array
  let pages = [
    <FirstPage project={project} user={user} schedules={schedules} scheduleId={scheduleid} />, // First Page
    <SelectLocation index={1} page={page} schedules={schedules} setNext={setNext} />, // Second Page
    <SelectDateTime index={2} page={page} appointmentTimeslots={appointmentTimeSlots} setNext={setNext} />, // Third Page
    <PurchaserForm index={3} page={page} schedules={schedules} project={project} setNext={setNext} user={user} />, // Fourth Page
    <ConfirmSubmission schedules={schedules} project={project} user={user} />, // Fifth Page
  ];

  if (schedules.length) {
    if (!/[0-9]/.test(schedules[0].locations[0]) || schedules[0].hideLocation) {
      pages = [
        <FirstPage project={project} user={user} schedules={schedules} scheduleId={scheduleid} />, // First Page
        <SelectDateTime index={1} page={page} appointmentTimeslots={appointmentTimeSlots} setNext={setNext} />, // Third Page
        <PurchaserForm index={2} page={page} schedules={schedules} project={project} setNext={setNext} user={user} />, // Fourth Page
        <ConfirmSubmission schedules={schedules} project={project} user={user} />, // Fifth Page
      ];
    }
  }

  // TODO: Figure out how the user will declare the type of appointment they are booking
  return loading ? (
    <LoadingWrapper title="Please Wait a Moment" modal={false} />
  ) : (
    <BookAppointmentWrapper className={'heightDiv'}>
      <div style={{ flex: '1', display: 'flex', height: '100dvh !important' }}>
        {transitions(({ opacity }, item) =>
          item === page ? (
            <animated.div
              style={{
                width: '100%',
                flex: '1',
                position: item === page ? 'static' : 'absolute',
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
              }}
            >
              {pages[page]}
            </animated.div>
          ) : (
            <animated.div
              style={{
                width: '100%',
                flex: '1',
                position: item === page ? 'static' : 'absolute',
                opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
              }}
            >
              {pages[prevPage]}
            </animated.div>
          )
        )}
      </div>
      <ButtonContainer style={{ padding: '10px 16px', marginBottom: '10px', position: !page ? 'absolute' : 'static', bottom: 0 }}>
        <NavButton
          offset={buttonOffset}
          variant="contained"
          style={{ left: buttonOffset }}
          onClick={() => ((page === firstPage || page === lastPage) && project.name !== 'RDS' ? goHome() : changePage(-1))}
        >
          {(page === firstPage || page === lastPage) && project.name !== 'RDS' ? 'Return to Portal' : 'Back'}
        </NavButton>
        {buttonDisplay ? (
          <NavButton
            offset={buttonOffset}
            style={{ right: buttonOffset, backgroundColor: `${nextEnabled ? 'green' : ''}` }}
            variant="contained"
            disabled={!nextEnabled || loading}
            onClick={() => {
              if (page === lastPage - 1) {
                submitAppointment();
                return;
              }
              changePage(1);
            }}
          >
            {handleButtonView()}
          </NavButton>
        ) : null}
      </ButtonContainer>
    </BookAppointmentWrapper>
  );
};

/* Types */

/* Styled Components */
const NavButton = styled(Button)<{ offset: number }>`
  :disabled:hover {
    background-color: transparent !important;
  }
  background-color: green;
  bottom: 0px;
`;

const BookAppointmentWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0px;
`;

/* Queries */
const GETAPPOINTMENTS = gql`
  query getAppointments($project: MongoID!, $today: String!, $type: String!, $cancelled: Boolean!, $scheduleId: String!) {
    appointmentMany(filter: { project: $project, dateGreaterThanEqual: $today, cancelled: $cancelled }, limit: 10000) {
      date
    }
    getSchedules(project: $project, type: $type, today: $today, cancelled: $cancelled, scheduleId: $scheduleId) {
      _id
      active
      allowGuests
      maxGuests
      public
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      questions {
        _id
        name
        question
        type
        subQuestion
        display
        choices {
          choice
          followUp {
            question
            _id
          }
        }
      }
      locations
      type
      access
      mainText
      endText
      emailEndText
      emailMainText
      name
      hideLocation
    }
  }
`;

/* Mutations */
const CREATEAPPOINTMENT = gql`
  mutation appointmentCreateOne($record: CreateOneAppointmentInput!, $realtorType: String) {
    appointmentCreateOne(record: $record, realtorType: $realtorType) {
      appointment {
        _id
      }
    }
  }
`;
export default BookAppointment;
